<template>
	<NuxtLink :to="item.url">
		<div
			class="flex justify-between px-5 py-3 cursor-pointer"
			@click="!item.url || item.key === 'products' ? emit('selected', item) : undefined"
		>
			<div class="flex gap-3">
				<Icon
					v-if="item.icon"
					:name="item.icon"
				/>
				<div :class="item.isBold ? 'font-bold' : ''">{{ item.name }}</div>
			</div>

			<Icon
				v-if="item.iconRight"
				:name="item.iconRight"
			/>
			<div v-else></div>
		</div>
		<div class="divider"></div>
	</NuxtLink>
</template>

<script lang="ts" setup>
import type { NavItemT } from "@/types/general";

interface Props {
	item: NavItemT;
}
defineProps<Props>();

const emit = defineEmits<{
	(e: "selected", item: NavItemT): void;
}>();
</script>
