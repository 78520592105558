<template>
	<v-menu>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center gap-1 cursor-pointer"
			>
				<button>
					{{ $t(`general.langs.${selected}`) }}
				</button>
				<Icon name="md-expand_more" />
			</div>
		</template>
		<div class="w-full bg-white shadow rounded-sm">
			<div
				v-for="lang in languages"
				:key="lang.code"
				class="py-3 px-4 hover:bg-gray-200 rounded-sm"
				@click="switchTo(lang)"
			>
				<button>{{ $t(`general.langs.${lang.name}`) }}</button>
			</div>
		</div>
	</v-menu>
</template>

<script setup lang="ts">
const { selected, languages, switchTo } = useTranslations();
</script>
