<template>
	<div class="border border-neutral-100 w-full rounded">
		<div class="flex justify-between pl-1 pr-3 py-4 text-sm items-center">
			<div class="font-semibold flex items-center gap-1">
				<Icon
					name="md-chevron_right"
					:class="iconClasses"
					@click="expand"
				/>
				<div>{{ props.title }}</div>
			</div>
			<slot name="status"></slot>
		</div>
		<section v-show="isExpanded">
			<div class="divider"></div>
			<p class="px-3 py-4 text-sm">
				{{ props.content }}
			</p>
		</section>
	</div>
</template>

<script setup lang="ts">
interface Props {
	title: string;
	content?: string;
}
const props = defineProps<Props>();

const isExpanded = ref(false);

const iconClasses = computed(() => {
	const base = "cursor-pointer transition-transform duration-200 ease-in-out ";
	const variable = isExpanded.value ? "-rotate-90" : "rotate-90";
	return base + variable;
});

function expand() {
	if (props.content?.length) isExpanded.value = !isExpanded.value;
}
</script>
