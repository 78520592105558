<template>
	<v-app v-if="platformInitialized">
		<NavModule />
		<v-main class="page-mt">
			<slot />
		</v-main>
		<Footer />
		<CookiesBanner />
	</v-app>
	<Loader v-else />
</template>

<script lang="ts" setup>
import NavModule from "@/components/Nav/NavModule.vue";
import Footer from "@/components/Footer/Footer.vue";
import CookiesBanner from "@/components/Cookies/CookiesBanner.vue";
import Loader from "@/components/Loader.vue";
const { platformInitialized } = storeToRefs(usePlatformStore());
</script>
