<template>
	<div v-if="showBanner">
		<div
			class="fixed bottom-0 w-full page-px py-5 bg-neutral-900 flex justify-between items-end lg:items-center text-white flex-col lg:flex-row gap-6 lg:gap-24"
		>
			<p>
				{{ $t("components.cookies.banner_msg") }}
				<span
					class="underline cursor-pointer"
					@click="iscookiesMenuOpen = true"
					>{{ $t("components.cookies.view_cookie_statement_btn") }}</span
				>
			</p>
			<div class="flex gap-3 items-center">
				<Button
					:height="40"
					variant="outlined"
					:style="{ borderRadius: plfm.btns.rounding + 'px' }"
					@click="iscookiesMenuOpen = true"
					>{{ $t("components.cookies.cookie_settings") }}</Button
				>
				<div class="bg-neutral-700 w-[1px] h-[40px]"></div>
				<Button
					:height="40"
					color="white"
					:style="{ borderRadius: plfm.btns.rounding + 'px' }"
					@click="acceptAllCookies"
					>{{ $t("components.cookies.accept_all") }}</Button
				>
			</div>
		</div>

		<v-navigation-drawer
			v-model="iscookiesMenuOpen"
			temporary
			fixed
			location="right"
			:width="xs ? 350 : 500"
		>
			<CookiesSettings
				@close-drawer="iscookiesMenuOpen = false"
				@accept-all="acceptAllCookies"
				@confirm-choices="savePreferences"
			/>
		</v-navigation-drawer>
	</div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import CookiesSettings from "@/components/Cookies/CookiesSettings.vue";
import type { CookiesPreferences } from "@/types/general.js";

const { xs } = useDisplay();
const { plfm } = storeToRefs(usePlatformStore());

const showBanner = ref(false);
const iscookiesMenuOpen = ref(false);

function acceptAllCookies() {
	const settings = {
		savedAt: Date.now(),
		performanceAndFunctionality: true,
		analyticsAndCustomization: true,
	};

	window.localStorage.setItem("cookiePreferences", JSON.stringify(settings));
	showBanner.value = false;
}

function savePreferences(pref: CookiesPreferences) {
	const settings = {
		...pref,
		savedAt: Date.now(),
	};

	window.localStorage.setItem("cookiePreferences", JSON.stringify(settings));
	showBanner.value = false;
}

onBeforeMount(() => {
	const cached = window.localStorage.getItem("cookiePreferences");
	if (!cached) showBanner.value = true;
});
</script>
