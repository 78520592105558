<template>
	<v-menu
		v-if="isLoggedIn"
		width="260"
	>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center desktop-nav-item"
				:class="props['aria-expanded'] === 'true' ? '!border-gray-300' : ''"
			>
				<Icon
					name="md-account_circle"
					class="mr-2"
				/>
				{{ $t("components.nav.my_account") }}
				<Icon
					class="transition-all duration-250"
					name="md-expand_more"
					:class="props['aria-expanded'] === 'true' ? 'rotate-180' : ''"
				/>
			</div>
		</template>
		<div class="w-full bg-white shadow rounded-sm">
			<UserInfo v-if="userType !== 'personal'" />
			<NuxtLink
				v-for="(item, i) in items"
				:key="i"
				:to="item.url"
				:target="item.external ? '_blank' : undefined"
				class="flex px-4 py-3 gap-2 hover:bg-neutral-50"
			>
				<div>{{ item.name }}</div>
			</NuxtLink>

			<div class="divider"></div>
			<NuxtLink
				v-if="userType === 'paidOrg'"
				:to="systemBaseUrl"
				target="_blank"
				class="flex px-4 py-3 justify-between hover:bg-neutral-50"
			>
				<div>{{ $t("components.nav.admin") }}</div>
				<Icon name="md-open_in_new" />
			</NuxtLink>
			<div
				class="flex px-4 py-3 justify-between hover:bg-neutral-50 cursor-pointer"
				@click="signOut"
			>
				<div>{{ $t("components.nav.logout") }}</div>
				<Icon name="md-logout" />
			</div>
		</div>
	</v-menu>
	<div
		v-else
		class="flex items-center"
	>
		<NuxtLink
			class="desktop-nav-item mr-[-1px]"
			to="/auth"
		>
			<div>{{ $t("components.nav.login") }}</div>
		</NuxtLink>
		<div class="h-[20px] w-[1px] bg-neutral-200"></div>
		<NuxtLink
			class="desktop-nav-item ml-[-1px]"
			to="/auth?tab=sign_up&accountType=business"
		>
			<div>{{ $t("components.nav.create_account") }}</div>
		</NuxtLink>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import UserInfo from "@/components/Nav/UserInfo.vue";

const router = useRouter();
const { t } = useI18n();
const { userLogout } = useUserStore();
const { clearCartData } = useCartStore();

const { systemBaseUrl, platformId } = storeToRefs(usePlatformStore());
const { isLoggedIn } = storeToRefs(useUserStore());

const userType = computed(() => {
	return "personal";
	// if (!user.value?.organization_id) return "personal";
	// const organization = [pseudo code, access user's organiation]
	// return organization.service_package ? "paidOrg" : "freeOrg";
});

const items = computed(() =>
	[
		{
			name: t("components.nav.saved_products"),
			url: "/",
			external: false,
			hidden: true,
		},
		{
			name: t("components.nav.orders"),
			url: "/orders",
			external: false,
			hidden: false,
		},
		{
			name: t("components.nav.quote_requests"),
			url: `${systemBaseUrl.value}/commerce/production-requests-quotes?platformId=${platformId.value}`,
			external: true,
			hidden: true,
		},
		{
			name: t("components.nav.accounting"),
			url: `${systemBaseUrl.value}/accounting`,
			external: true,
			hidden: true,
		},
		{
			name: t("components.nav.my_business"),
			url: `${systemBaseUrl.value}/my-business/business-details`,
			external: true,
			hidden: true,
		},
		{
			name: t("components.nav.my_account"),
			url: "/my-account",
			external: false,
			hidden: true, // userType.value !== "personal",
		},
	].filter(item => !item.hidden)
);

async function signOut() {
	await userLogout();
	clearCartData();
	router.push("/");
}
</script>
