import { useI18n } from "vue-i18n";

export function useTranslations() {
	const supportedLanguages = [
		{ name: "english", code: "en" },
		{ name: "french", code: "fr" },
		{ name: "spanish", code: "es" },
	];

	const { locale } = useI18n();
	const selected = ref("english");
	const languages = computed(() => {
		return supportedLanguages.filter(lg => lg.name !== selected.value);
	});

	function switchTo(lang: { name: string; code: string }) {
		if (!supportedLanguages.find(l => l.code === lang.code)) return;
		selected.value = lang.name;
		locale.value = lang.code;
		localStorage.setItem("lang", JSON.stringify(lang));
	}

	onMounted(() => {
		const cached = localStorage.getItem("lang");
		if (cached) switchTo(JSON.parse(cached));
	});

	return { selected, languages, switchTo };
}
