<template>
	<div
		class="page-px py-5 flex flex-wrap md:justify-between items-center border-t border-neutral-100 text-[12px] text-neutral-900 leading-[18px] font-normal"
	>
		<div class="basis-full text-center md:text-left md:basis-auto">
			{{ platformName }} © {{ new Date().getFullYear() }} {{ $t("components.footer.rights") }}
		</div>
		<div class="basis-full md:basis-auto flex-wrap flex items-center gap-2 justify-center">
			<div class="flex gap-2 basis-full md:basis-auto justify-center mt-[0.5rem] md:mt-0">
				<a
					href="https://tengiva.com/terms-of-service"
					target="_blank"
					>{{ $t("components.footer.terms") }}</a
				>
				<span class="text-neutral-200">|</span>
				<a
					href="https://tengiva.com/privacy-policy"
					target="_blank"
					>{{ $t("components.footer.privacy") }}</a
				>
				<span class="text-neutral-200 hidden md:block">|</span>
			</div>
			<div class="flex justify-center items-center gap-2 basis-full md:basis-auto">
				<div>{{ $t("general.powered_by") }}</div>
				<TengivaLogo
					color="black"
					class="w-[79px] h-[16px] md:w-[118px] md:h-[24px] mt-[4px]"
				/>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import TengivaLogo from "../TengivaLogo.vue";
const { platformName } = storeToRefs(usePlatformStore());
</script>
