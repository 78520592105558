<template>
	<div
		class="h-[30px] w-full z-10 bg-neutral-50 fixed top-0 hidden md:flex justify-between items-center page-px text-[14px]"
	>
		<div v-if="plfm.isEditorial">
			<LanguageDropdown v-if="featureFlags.translations" />
		</div>

		<div :class="plfm.isEditorial ? 'absolute left-1/2 -translate-x-1/2' : ''">
			{{ topBannerMessage }}
		</div>
		<div class="flex items-center gap-4">
			<NuxtLink
				v-if="showAboutPage"
				to="/about-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.about_us") }}</NuxtLink
			>
			<NuxtLink
				to="/contact-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.contact") }}</NuxtLink
			>
			<LanguageDropdown v-if="!plfm.isEditorial && featureFlags.translations" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import LanguageDropdown from "@/components/Nav/LanguageDropdown.vue";

const { featureFlags, plfm, topBannerMessage, showAboutPage } = storeToRefs(usePlatformStore());
</script>
