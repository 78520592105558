<template>
	<div class="flex flex-column justify-between h-full">
		<div>
			<!-- Section 1: Header -->
			<div
				class="w-full flex py-4 px-5"
				:class="viewedMenu.length ? 'justify-between' : 'justify-end'"
			>
				<div
					v-if="viewedMenu.length"
					class="flex cursor-pointer"
					@click="goBack"
				>
					<Icon name="md-chevron_left" />
					<div>{{ $t("components.navigations.label.back") }}</div>
				</div>
				<Icon
					name="md-close"
					class="cursor-pointer"
					@click="emit('closeDrawer')"
				/>
			</div>
			<div>
				<UserInfo v-if="showAvatar" />
				<div
					v-else
					class="text-xl font-bold mb-4 px-5"
				>
					{{ title }}
				</div>
				<div class="divider"></div>
			</div>

			<!-- Section 2: top menu -->
			<template v-if="!viewedMenu.length">
				<NavItem
					v-for="route in topMenu"
					:key="route.name"
					:item="route"
					@selected="processClickedItem"
				/>
			</template>

			<!-- Section 3: sub menus -->
			<template v-if="viewedMenu.length">
				<NavItem
					v-for="route in viewedMenu[viewedMenu.length - 1].sublist || []"
					:key="route.name"
					:item="route"
					@selected="processClickedItem"
				/>
			</template>
		</div>

		<!-- Section 4: bottom menu -->
		<div>
			<div class="divider"></div>
			<div v-if="!viewedMenu.length">
				<NavItem
					v-for="route in bottomMenu"
					:key="route.name"
					:item="route"
					@selected="processClickedItem"
				/>
			</div>

			<div class="flex items-center justify-center py-6">
				<span class="text-[12px] mr-1">{{ $t("general.powered_by") }}</span>
				<TengivaLogo
					color="black"
					class="w-[118px] h-[24px] mt-[4px]"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import TengivaLogo from "@/components/TengivaLogo.vue";
import NavItem from "@/components/Nav/NavItemMobile.vue";
import UserInfo from "@/components/Nav/UserInfo.vue";
import type { NavItemT } from "@/types/general";

interface Props {
	isMobileDrawerOpen: boolean;
}
const props = defineProps<Props>();

const router = useRouter();
const { t } = useI18n();
const { setProducts, setFilters, setOrder } = useCatalogStore();
const { userLogout } = useUserStore();

const { platformId, showAboutPage, collections, featureFlags, systemBaseUrl } = storeToRefs(usePlatformStore());
const { isLoggedIn } = storeToRefs(useUserStore());
const { selected, languages, switchTo } = useTranslations();

const viewedMenu = ref<NavItemT[]>([]);

const title = computed(() => {
	return !viewedMenu.value.length ? t("components.nav.menu") : viewedMenu.value[viewedMenu.value.length - 1].name;
});

const showAvatar = computed(
	() => false && viewedMenu.value.length && viewedMenu.value[viewedMenu.value.length - 1].key === "account"
);
const topMenu = computed<NavItemT[]>(() =>
	[
		{
			key: "products",
			name: t("components.nav.products"),
			url: "/textiles",
			icon: "md-menu",
		},
		{
			key: "collections",
			name: t("components.nav.collections"),
			icon: "md-grid_view",
			iconRight: "md-chevron_right",
			sublist: collections.value,
		},
		{
			key: "merchants",
			name: t("components.nav.merchants"),
			url: "/merchants",
			icon: "md-store",
		},
		// {
		// 	key: "blog",
		// 	name: t("components.nav.blog"),
		// 	url: "/blog",
		// 	icon: "md-menu",
		// },
		{
			key: "about-us",
			name: t("components.nav.about_us"),
			url: "/about-us",
			icon: "md-info",
			hidden: !showAboutPage.value,
		},
		{
			key: "contact-us",
			name: t("components.nav.contact"),
			url: "/contact-us",
			icon: "md-mail",
		},
	].filter(item => !item.hidden)
);

const accountMenu = ref<NavItemT[]>(
	[
		{
			key: "saved-products",
			name: t("components.nav.saved_products"),
			url: "/",
			hidden: true,
		},
		{
			key: "orders",
			name: t("components.nav.orders"),
			url: "/orders",
		},
		{
			key: "quote-requests",
			name: t("components.nav.quote_requests"),
			url: `${systemBaseUrl.value}/commerce/production-requests-quotes?platformId=${platformId.value}`,
			external: true,
			hidden: true,
		},
		{
			key: "accounting",
			name: t("components.nav.accounting"),
			url: `${systemBaseUrl.value}/accounting`,
			external: true,
			hidden: true,
		},
		{
			key: "my-business",
			name: t("components.nav.my_business"),
			url: `${systemBaseUrl.value}/my-business/business-details`,
			external: true,
			hidden: true,
		},
		{
			key: "my-account",
			name: t("components.nav.my_account"),
			url: "/my-account",
			hidden: true,
		},
		{
			key: "admin",
			name: t("components.nav.admin"),
			url: systemBaseUrl.value,
			external: true,
			iconRight: "md-open_in_new",
			hidden: true,
		},
		{
			key: "signout",
			name: t("components.nav.logout"),
			iconRight: "md-logout",
		},
	].filter(item => !item.hidden)
);

const languagesMenu = computed(() =>
	languages.value.map(lang => {
		return {
			key: lang.name,
			name: t(`general.langs.${lang.name}`),
			code: lang.code,
		};
	})
);

const bottomMenu = computed<NavItemT[]>(() =>
	[
		{
			key: "account",
			name: t("components.nav.my_account"),
			icon: "md-account_circle",
			iconRight: "md-chevron_right",
			hidden: !isLoggedIn.value,
			sublist: accountMenu.value,
		},
		{
			key: "login",
			name: t("components.nav.login"),
			url: "/auth",
			hidden: isLoggedIn.value,
		},
		{
			key: "signup",
			name: t("components.nav.create_account"),
			url: "/auth?tab=sign_up&accountType=business",
			hidden: isLoggedIn.value,
		},
		{
			key: "langs",
			name: t(`general.langs.${selected.value}`),
			icon: "md-language",
			iconRight: "md-chevron_right",
			hidden: !featureFlags.value.translations,
			sublist: languagesMenu.value,
		},
	].filter(item => !item.hidden)
);

const emit = defineEmits<{
	(e: "closeDrawer"): void;
}>();

watch(
	() => props.isMobileDrawerOpen,
	newVal => {
		if (newVal) {
			viewedMenu.value = [];
		}
	}
);

function goBack() {
	viewedMenu.value.pop();
}

async function signOut() {
	await userLogout();
	emit("closeDrawer");
	router.push("/");
}

function processClickedItem(item: NavItemT) {
	if (item.key === "signout") signOut();
	else if (item.key === "products") handleTextileLinkClick();
	else if (viewedMenu.value.length && viewedMenu.value[0].key === "langs" && item.code) {
		switchTo({ name: item.key, code: item.code });
		emit("closeDrawer");
	} else viewedMenu.value.push(item);
}

const handleTextileLinkClick = () => {
	if (router.currentRoute?.value.name === "textiles") {
		setFilters({});
		setOrder("");
		setProducts(platformId.value);
	}
};
</script>
