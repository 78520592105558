<template>
	<div class="flex gap-4 px-4 py-3">
		<Avatar
			:controls="false"
			:src="avatar.imgUrl || undefined"
			:placeholder="avatar.initials || undefined"
			size="small"
		/>
		<div>
			<div class="font-semibold">{{ name }}</div>
			<div class="text-xs text-[#505055]">{{ orgName }}</div>
		</div>
	</div>
	<div class="divider"></div>
</template>

<script setup lang="ts">
const { imgixEcmOdcUrl } = storeToRefs(usePlatformStore());
const { user, userOrganization } = storeToRefs(useUserStore());

const { setUserOrganization } = useUserStore();

if (!userOrganization.value && user.value?.organization_id) {
	setUserOrganization(user.value?.organization_id);
}

const name = computed(() => `${user.value?.first_name} ${user.value?.last_name}`);
const initials = computed(() =>
	user.value ? user.value?.first_name.charAt(0) + user.value?.last_name.charAt(0) : "U"
);
const orgName = computed(() => userOrganization.value?.profile?.public_name);
const avatar = computed(() => {
	// Pictures not working to be investigated later
	const picId = null; // user.value?.picture_id;
	return {
		imgUrl: picId ? `${imgixEcmOdcUrl.value}/${picId}` : null,
		initials: !picId ? initials.value : null,
	};
});
</script>
