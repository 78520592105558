<template>
	<img
		:src="logoSrc"
		alt="tengiva-logo"
	/>
</template>

<script lang="ts" setup>
interface TengivaLogoProps {
	color: "black" | "white";
	iconOnly?: boolean;
}

const props = defineProps<TengivaLogoProps>();

const logoSrc = computed(() => {
	let logoPath = `/images/logo-${props.color}`;
	if (props?.iconOnly) {
		logoPath += "-icon-only";
	}
	return `${logoPath}.svg`;
});
</script>
