<template>
	<div class="flex flex-column justify-between min-h-screen">
		<div class="mb-[15vh]">
			<div class="w-full text-right py-4 px-4">
				<Icon
					name="md-close"
					class="cursor-pointer"
					@click="emit('closeDrawer')"
				/>
			</div>
			<div class="spacer">
				<div class="text-xl font-bold mb-4">{{ $t("components.cookies.cookie_settings") }}</div>
				<p class="text-sm">
					{{ $t("components.cookies.disclaimer") }}
				</p>
				<Button
					class="my-3 tg-btn"
					:height="40"
					block
					color="primary"
					@click="emit('acceptAll')"
					>{{ $t("components.cookies.accept_all") }}</Button
				>
				<Button
					:height="40"
					block
					variant="outlined"
					:style="{ borderRadius: plfm.btns.rounding + 'px' }"
					@click="unavailableMsgs.push('underAcceptBtn')"
					>{{ $t("components.cookies.accept_only_necessary") }}</Button
				>
				<div :class="`text-[#D92D20] text-xs mt-2 ${!unavailableMsgs.includes('underAcceptBtn') ? 'opacity-0' : ''}`">
					{{ $t("components.cookies.unavailable_msg") }}
				</div>
				<div class="font-semibold mt-4 mb-3">{{ $t("components.cookies.manage_consent") }}</div>
				<CookieType
					:title="$t('components.cookies.essentials')"
					:content="$t('components.cookies.essentials_description')"
					class="mb-2"
				>
					<template #status>
						<div class="font-semibold text-[#0E6862]">{{ $t("components.cookies.always_active") }}</div>
					</template>
				</CookieType>
				<CookieType
					:title="$t('components.cookies.performance')"
					class="mb-2"
					@click="unavailableMsgs.push('underExpansionPanels')"
				>
					<template #status>
						<Switch
							v-model="performanceCookie"
							readonly
						/>
					</template>
				</CookieType>
				<CookieType
					:title="$t('components.cookies.analytics')"
					@click="unavailableMsgs.push('underExpansionPanels')"
				>
					<template #status>
						<Switch
							v-model="analyticsCookie"
							readonly
						/>
					</template>
				</CookieType>
				<div
					:class="`text-[#D92D20] text-xs mt-2 ${!unavailableMsgs.includes('underExpansionPanels') ? 'opacity-0' : ''}`"
				>
					{{ $t("components.cookies.unavailable_msg") }}
				</div>
			</div>
		</div>
		<div class="absolute bottom-0 w-full bg-white">
			<div class="divider"></div>
			<div class="spacer py-5">
				<Button
					class="tg-btn"
					:height="40"
					block
					color="primary"
					@click="confirm"
					>{{ $t("components.cookies.confirm_my_choices_btn") }}</Button
				>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import CookieType from "@/components/Cookies/CookieType.vue";
const emit = defineEmits<{
	(e: "closeDrawer"): void;
	(e: "acceptAll"): void;
	(
		e: "confirmChoices",
		obj: {
			performanceAndFunctionality: boolean;
			analyticsAndCustomization: boolean;
		}
	): void;
}>();

const { plfm } = storeToRefs(usePlatformStore());

const performanceCookie = ref(true);
const analyticsCookie = ref(true);
const unavailableMsgs = ref<string[]>([]);

function confirm() {
	emit("confirmChoices", {
		performanceAndFunctionality: performanceCookie.value,
		analyticsAndCustomization: analyticsCookie.value,
	});
}

onMounted(() => {
	interface Preferences {
		savedAt: number;
		performanceAndFunctionality: boolean;
		analyticsAndCustomization: boolean;
	}

	const cached = window.localStorage.getItem("cookiePreferences");
	if (cached) {
		const preferences = JSON.parse(cached) as Preferences;
		performanceCookie.value = preferences.performanceAndFunctionality;
		analyticsCookie.value = preferences.analyticsAndCustomization;
	}
});
</script>

<style lang="scss" scoped>
.spacer {
	@apply px-4 sm:px-14;
}

:deep(.v-selection-control__input) {
	height: 24px;
}
:deep(.selection-control__wrapper) {
	height: 24px;
}

:deep(.v-switch .v-selection-control) {
	min-height: unset;
	height: 24px;
}
</style>
